import React, { useContext, useEffect, useState } from "react";
import { NavLink } from "react-router-dom";
import Header from "../Common/Header";
import Banner from "../Elements/Banner";
import Footer from "../Common/Footer";
import httpClient from "../../AxiosConfig";
import { host } from "../../settings";
import { AppContext } from "../../contexts/AppContext";

const image = require("../../images/projects/portrait/pic6.jpg");

var bnrimg = require("./../../images/banner/1.jpg");

const OurProject = () => {
  const [categories, setCategories] = useState([]);
  const [activeCategory, setActiveCategory] = useState([]);
  const { productState, setProducts } = useContext(AppContext);
  const [isLoadingProducts, setIsLoadingProducts] = useState(true);
  const [isLoadingCategories, setIsLoadingCategories] = useState(true);

  useEffect(async () => {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");

    await getProducts();
    await getCategories();
  }, []);

  const getProducts = async () => {
    try {
      const res = await httpClient.get("product/list-with-categories");
      if (res.status === 200) {
        let products = [];

        res.data.forEach((product, index) => {
          products.push({
            image: product.url ? host + product.url : image.default,
            title: product.productName,
            address: product.description,
            specs: product.specs,
            filter: product.productCategoryId,
            width: index === "col-md-3",
            filterName: product.productCategory?.name,
            make: product.makeString,
            id: product.id,
            images: product.images,
          });
        });

        setProducts({
          products: products,
          filteredProducts: products,
        });
        setIsLoadingProducts(false);
      }
    } catch (e) {
      // TODO toaster
    }
  };

  const getCategories = async () => {
    try {
      const res = await httpClient.get("product/categories");
      if (res.status === 200) {
        let categories = [];

        categories.push({
          filter: 0,
          label: "All",
        });

        res.data.forEach((category) => {
          categories.push({
            filter: category.id,
            label: category.name,
          });

          if (category.children?.length > 0) {
            category.children.forEach((child) => {
              categories.push({
                filter: child.id,
                label: child.name,
              });

              if (child.children?.length > 0) {
                child.children.forEach((subChild) => {
                  categories.push({
                    filter: subChild.id,
                    label: subChild.name,
                  });
                });
              }
            });
          }
        });

        setCategories(categories);

        setIsLoadingCategories(false);
      }
    } catch (e) {
      //
    }
  };

  const handleSelectCategory = (id) => {
    selectProducts(id);
    setActiveCategory(id);
  };

  const selectProducts = (id) => {
    if (id === 0) {
      // ALL
      setProducts({ filteredProducts: productState.products });
      return;
    }

    const selectedProducts = productState.products.filter(
      (item) => item.filter === id
    );
    setProducts({ filteredProducts: selectedProducts });
  };

  const filterProducts = (event) => {
    const query = event.target.value;

    if (query.length < 1) {
      setProducts({ filteredProducts: productState.products });
      return;
    }

    const filteredProducts = productState.products.filter(
      (item) =>
        item.title.toLowerCase().includes(query.toLowerCase()) ||
        item?.make?.toLowerCase().includes(query.toLowerCase()) ||
        item?.filterName?.toLowerCase().includes(query.toLowerCase())
    );

    setProducts({ filteredProducts: filteredProducts });
  };

  return (
    <>
      <Header />
      <div className="page-content">
        <Banner
          title="At GeoTech, we support what we sell."
          pagename="Services"
          bgimage={bnrimg.default}
        />

        <div className="section-full mobile-page-padding p-t80 p-b30 square_shape2 ">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-left">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">Our</span>{" "}
                    Products
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="section-content">
              <div className="portfolio-wrap prod-cat-filter row mfp-gallery product-stamp clearfix">
                {/* COLUMNS 1 */}
                <div className="stamp col-lg-3 col-md-6 col-sm-6 m-b30 bg-gray p-a30">
                  <div className="stamp bg-gray p-a30 ">
                    <div className="filter-wrap">
                      <input
                        onChange={filterProducts}
                        className="filter-search"
                        placeholder="Type to search"
                      />
                      <ul className="filter-navigation masonry-filter text-uppercase">
                        {categories.map((item, index) => (
                          <li
                            key={index}
                            onClick={() => handleSelectCategory(item.filter)}
                          >
                            <NavLink
                              to={"#"}
                              data-filter={item.filter}
                              className={`${
                                activeCategory === item.filter ? "active" : ""
                              }`}
                              data-hover={item.label}
                            >
                              {item.label}
                            </NavLink>
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                </div>
                {/* COLUMNS 2 */}
                <div className="col-lg-8 col-md-3 col-sm-6">
                  {productState.filteredProducts.map((item, index) => (
                    <div
                      key={index}
                      className={`${item.filter} masonry-item ${item.width} col-lg-3 col-md-4 col-sm-6`}
                    >
                      <div className="mt-box masonry-item-ins image-hover-block">
                        <div className="img-div">
                          <img src={item.image} alt="" />
                        </div>
                        <div className="mt-info  p-t20 text-white">
                          <h4 className="mt-tilte m-b10 m-t0">{item.title}</h4>
                          <p className="m-b0">
                            {item.address?.length > 40
                              ? item.address?.substring(0, 40)
                              : item.address}
                          </p>
                        </div>
                        <NavLink to={"/products/" + item.id}></NavLink>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          </div>
          <div className="hilite-title text-left p-l50 text-uppercase text-pop-up-top">
            {/*<strong>Products</strong>*/}
          </div>
        </div>
        <Footer />
      </div>
    </>
  );
};

export default OurProject;
