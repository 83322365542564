import React from "react";
import { NavLink } from "react-router-dom";

class Faq extends React.Component {
  render() {
    return (
      <>
        <div className="section-full p-t80 p-b50 bg-white inner-page-padding">
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-md-6 col-sm-6">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">
                            Some
                          </span>{" "}
                          FAQ
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  <div className="section-content p-b30">
                    <div className="mt-accordion acc-bg-gray" id="accordion5">
                      <div className="panel mt-panel">
                        <div className="acod-head acc-actives">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseOne5"
                              data-parent="#accordion5"
                            >
                              How often should my device be serviced?
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div
                          id="collapseOne5"
                          className="acod-body collapse in"
                        >
                          <div className="acod-content p-tb15">
                            <p>
                              {" "}
                              Regular servicing according to our maintenance
                              plan that we recommend is the best way to keep
                              your work equipment in optimal condition and
                              ensuring longevity. <br />
                              For an excellent working condition, it is
                              recommended that they be subjected to a regular
                              and systematic service.
                              <br />
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseTwo5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Optical instruments (Total Stations, Theodolites,
                              Levels, etc.)
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseTwo5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                            Annual calibration and servicing.
                            <br />
                            In addition: <br />
                            - After any accidental shock or fall,
                            <br />
                            - If you notice changes in accuracy or performance,
                            <br />- If you notice changes in accuracy or
                            performance.
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseThree5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              Construction lasers
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseThree5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                            Annual calibration and servicing.
                            <br />
                            - After any accidental shock or fall,
                            <br />
                            - If you notice changes in accuracy or performance,
                            <br />- If you notice changes in accuracy or
                            performance.
                          </div>
                        </div>
                      </div>
                      <div className="panel mt-panel">
                        <div className="acod-head">
                          <h6 className="acod-title">
                            <a
                              data-toggle="collapse"
                              href="#collapseFour5"
                              className="collapsed"
                              data-parent="#accordion5"
                            >
                              GPS / GNSS Receivers and Controllers
                              <span className="indicator">
                                <i className="fa" />
                              </span>
                            </a>
                          </h6>
                        </div>
                        <div id="collapseFour5" className="acod-body collapse">
                          <div className="acod-content p-tb15">
                            Annual servicing.
                            <br />
                            GNSS technology and Controllers are constantly
                            evolving and improving.
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-md-6 col-sm-6">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">
                            Our
                          </span>{" "}
                          Solution
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  <div className="row">
                    <div className="col-md-6">
                      <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-gray center">
                        <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-gray  scale-in-center">
                          <span className="icon-cell text-primary">
                            <img
                              src={"/assets/media/images/lang-region-icon.svg"}
                              alt={""}
                            />
                          </span>
                        </div>
                        <div className="icon-content text-black">
                          <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                            Contact Us
                          </h4>
                          <p>
                            Get our contact information and location for <br />
                            Albania or Kosovo.{" "}
                          </p>
                          <NavLink
                            to="/accordian"
                            className="site-button-link"
                            data-hover="Read More"
                          >
                            Contact info
                            <i className="fa fa-angle-right arrow-animation" />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-6">
                      <div className="mt-icon-box-wraper p-a25 m-b30 onhover-box-shadow bg-dark center">
                        <div className="mt-icon-box-sm inline-icon text-primary  m-b20 radius bg-secondry  scale-in-center">
                          <span className="icon-cell text-primary">
                            <i className="flaticon-ui" />
                          </span>
                        </div>
                        <div className="icon-content text-white">
                          <h4 className="mt-tilte text-uppercase font-weight-600 m-b20">
                            Fill online form
                          </h4>
                          <p>
                            Get our online form, and submit your data, we will
                            contact your back soon!
                          </p>
                          <NavLink
                            to="/accordian"
                            className="site-button-link yellow"
                            data-hover="Read More"
                          >
                            Form{" "}
                            <i className="fa fa-angle-right arrow-animation" />
                          </NavLink>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* FAQ */}
        </div>
      </>
    );
  }
}

export default Faq;
