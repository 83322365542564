import React from 'react';
import Components from './components/Components';
import ScrolToTop from './components/Elements/ScrolToTop';
import Loader from "./components/Elements/Loader";
import {AppContextProvider} from "./contexts/AppContext";

const App = () => {

    return (
        <AppContextProvider>
            <div className="App">
                <Components/>
                <ScrolToTop/>
                <Loader/>
            </div>
        </AppContextProvider>
    );
}

export default App;