import React from "react";
import { NavLink } from "react-router-dom";

var bnr1 = require("./../../images/background/bg-5.png");

class Specialization extends React.Component {
  render() {
    return (
      <>
        <div
          className="section-full mobile-page-padding bg-white p-t80 p-b30 bg-repeat square_shape1"
          // style={{ backgroundImage: "url(" + bnr1.default + ")" }}
        >
          <div className="container">
            {/* IMAGE CAROUSEL START */}
            <div className="section-content">
              <div className="row">
                <div className="col-lg-3 col-md-4 col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img
                      src={require("../../images/pic1.jpg").default}
                      alt=""
                    />
                    <div className="figcaption">
                      <h4>Accurate and reliable geodetic instruments.</h4>
                      <p></p>
                      <NavLink to="/about">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img
                      src={require("../../images/pic2.jpg").default}
                      alt=""
                    />
                    <div className="figcaption">
                      <h4>Servicing and calibration solutions.</h4>
                      <p></p>
                      <NavLink to="/about">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-4  col-sm-6 m-b30">
                  <div className="image-effect-one hover-shadow">
                    <img
                      src={require("../../images/pic3.jpg").default}
                      alt=""
                    />
                    <div className="figcaption bg-dark">
                      <h4>Successful internships and effective training.</h4>
                      <p></p>
                      <NavLink to="/about">
                        <i className="link-plus bg-primary" />
                      </NavLink>
                    </div>
                  </div>
                </div>
                <div className="col-lg-3 col-md-12 col-sm-6 m-b30">
                  <div className="mt-box our-speciallization-content">
                    <h3 className="m-t0">
                      <span className="font-weight-100">Geodetic</span> <br />
                      Solutions.
                    </h3>
                    <p>
                      You are looking for GPS, Total Station, or professional
                      work, and you are in the dilemma of which is a better
                      choice for you. You may need free consultations.
                    </p>
                    <NavLink to="/about" className="site-button btn-effect">
                      Contact us
                    </NavLink>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </>
    );
  }
}

export default Specialization;
