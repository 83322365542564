import React from 'react';
import {Link} from "react-router-dom";

class AboutCompany extends React.Component {
    render() {
        return (
            <>
                <div className="section-full p-t80 bg-white">
                    <div className="container">
                        <div className="section-content ">
                            <div className="m-service-containt text-black">
                                <div className="row">
                                    <div className="col-md-5 col-sm-12">
                                        <div className="service-about-left">
                                            <div className="mt-media">
                                                <img src={require('./../../images/s-1.png').default} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="col-md-7 col-sm-12">
                                        <div className="service-about-right m-b30">
                                            <h3 className="m-t0">Maintenance | Repair | Calibration </h3>
                                            <p>
                                                Continuous maintenance for Geodetic products is crucial in securing that
                                                your equipment gives high efficiency during geodetic works.
                                                External factors, daily use, collisions and shocks during transport,
                                                large temperature changes greatly affect the quality and performance of
                                                equipment.<br/>
                                                The GeoTECH Service Center is equipped with various systems for the
                                                calibration of instruments that are put into operation, especially for
                                                the calibration of surveillance instruments and we work closely with the
                                                device manufacturer to be notified of any recent firmware updates to the
                                                device, software applications, and service notification.<br/>
                                                <ul>
                                                    <li>Total optical calibration system, for control and calibration of
                                                        all types of geodetic instruments.
                                                    </li>
                                                    <li>EDM collimator LT-CB-30 Vertical Collimator Frame for laser
                                                        calibration (EDM) at total stations, a unique system of its kind
                                                        in the region.
                                                    </li>
                                                    <li>A zero-baseline system for GNSS receiver control.</li>
                                                    <li>Total automatic laser calibration system, for control,
                                                        adjustment and calibration of all types of rotary lasers.
                                                    </li>
                                                </ul>
                                            </p>
                                            <div className="call-for-quote-outer">
                                                <div className="call-quote">
                                                    <span>Call For a Quote:</span>
                                                    <h4>(+383) 49 74 74 79</h4>
                                                </div>
                                                <div className="call-estimate bg-dark">
                                                    <Link to={"/accordian"} className="site-button-secondry btn-effect">Online
                                                        Form</Link>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
}

export default AboutCompany;