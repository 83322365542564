import React from "react";
import { NavLink } from "react-router-dom";
import Switcher from "../Elements/Switcher";

var bgimage = require("./../../images/background/bg-site.png");

class Footer extends React.Component {
  constructor(props) {
    super(props);
    this.currentstate = {
      logo: require("./../../images/logo-dark.png").default,
    };
  }

  updateFooterLogo = (updatedlogo) => {
    this.currentstate.logo = updatedlogo.default;
  };

  render() {
    return (
      <>
        <footer className="site-footer footer-large  footer-dark	footer-wide">
          {/* FOOTER BLOCKES START */}
          <div className="footer-top overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                {/* ABOUT COMPANY */}
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget widget_about">
                    {/*<h4 class="widget-title">About Company</h4>*/}
                    <div className="logo-footer clearfix p-b15">
                      <NavLink to={"/"}>
                        <img src={this.currentstate.logo} alt="" />
                      </NavLink>
                    </div>
                    <p className="max-w400">
                      {" "}
                      Committed to provide products and solutions that are
                      precise, reliable and designed to meet the needs of our
                      clients in surveillance and measurement.
                    </p>
                    <ul className="social-icons  mt-social-links">
                      <li>
                        <a
                          href="https://www.facebook.com/geotech.al/"
                          className="fa fa-facebook"
                        ></a>
                      </li>
                      <li>
                        <a
                          href="https://www.linkedin.com/company/geotech/about/"
                          className="fa fa-linkedin"
                        ></a>
                      </li>
                      <li>
                        <a
                          href="https://www.instagram.com/geotech_al/?hl=en"
                          className="fa fa-instagram"
                        ></a>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* RESENT POST */}
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <div className="widget widget_address_outer">
                    <h4 className="widget-title">Contact Us</h4>
                    <ul className="widget_address">
                      <li>
                        St. “George W. Bush”, Kulla 1, Kati 1, Nr. 20, 1000,
                        Tirana
                      </li>
                      <li>
                        St. B – Muharrem Fejza, Kompleksi KAWA, A, nr.3. 10000,
                        Prishtina
                      </li>
                      <li>info@geotech.al</li>
                      <li>(+355) 67 20 88 060</li>
                      <li>(+383) 49 74 74 79</li>
                    </ul>
                  </div>
                </div>
                {/* USEFUL LINKS */}
                <div className="col-lg-4 col-md-6 col-sm-6 footer-col-3">
                  <div className="widget widget_services inline-links">
                    <h4 className="widget-title">Useful links</h4>
                    <ul>
                      <li>
                        <NavLink to={"/about"}>About</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/products"}>Products</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/services"}>Services</NavLink>
                      </li>
                      <li>
                        <NavLink to={"/accordian"}>Contact Us</NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
                {/* TAGS */}
                {/* NEWSLETTER */}
              </div>
            </div>
          </div>
          {/* FOOTER COPYRIGHT */}
          <div className="footer-bottom overlay-wraper">
            <div className="overlay-main" />
            <div className="container">
              <div className="row">
                <div className="mt-footer-bot-center">
                  <span className="copyrights-text">© 2022 Geotech.</span>
                </div>
              </div>
            </div>
          </div>
        </footer>
        <Switcher updateFooterLogo={this.updateFooterLogo.bind(this)} />
      </>
    );
  }
}

export default Footer;
