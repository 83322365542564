import React, {createContext, useReducer} from 'react';

const initProductState = {
    products: [],
    filteredProducts: [],
}

function reducer(productState, action) {
    return {...productState, ...action};
}

export const AppContext = createContext({});

export const AppContextProvider = (props) => {
    const [productState, setProducts] = useReducer(reducer, {
        products: [],
        filteredProducts: [],
    }, () => initProductState);

    return (
        <AppContext.Provider value={{productState, setProducts}}>
            {props.children}
        </AppContext.Provider>
    )
}