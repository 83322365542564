import React from "react";
import { NavLink } from "react-router-dom";

var bnrimg = require("./../../images/banner/2.jpg");

const filters = [
  { label: "Construction", filter: ".cat-1" },
  { label: "Renovation", filter: ".cat-2" },
  { label: "Outdoor", filter: ".cat-3" },
  { label: "Interiors", filter: ".cat-4" },
  { label: "Consulting", filter: ".cat-5" },
];

const projects = [
  {
    thumb: require("./../../images/projects/portrait/pic1.jpg"),
    image: require("./../../images/projects/portrait/pic4.jpg"),
    title: "Sokkia",
    description: `Manufacturer of GIS and GPS/GNSS receivers, theodolites, levels, 3D coordinate measuring and laser systems for measuring distance, position and area.`,
    filter: "cat-1",
  },
  {
    thumb: require("./../../images/projects/portrait/pic2.jpg"),
    image: require("./../../images/projects/portrait/pic9.jpg"),
    title: "Topcon",
    description: `Precise solutions for growth - From proposal to mapping, design to layout to inspection, our innovative technology is engineered to drive your productivity.`,
    filter: "cat-2",
  },
  {
    thumb: require("./../../images/projects/portrait/pic3.jpg"),
    image: require("./../../images/projects/portrait/pic6.jpg"),
    title: "Wingtra",
    description: `Easy-to-use mapping drone that delivers consistent, high-quality survey data faster and at a lower cost than other surveying tools.`,
    filter: "cat-3",
  },
];

class ProjectGrid extends React.Component {
  componentDidMount() {
    function loadScript(src) {
      return new Promise(function (resolve, reject) {
        var script = document.createElement("script");
        script.src = src;
        script.addEventListener("load", function () {
          resolve();
        });
        script.addEventListener("error", function (e) {
          reject(e);
        });
        document.body.appendChild(script);
        document.body.removeChild(script);
      });
    }

    loadScript("./assets/js/masonary.js");
  }

  render() {
    return (
      <>
        <div className="page-content m-b5 m-t30">
          {/* SECTION CONTENT START */}
          <div className="section-full p-tb80 inner-page-padding m-b5">
            <div className="container">
              {/* TITLE START */}
              <div className="section-head">
                <div className="mt-separator-outer separator-center">
                  <div className="mt-separator">
                    <h2 className="text-uppercase sep-line-one ">
                      <span className="font-weight-300 text-primary">Our</span>{" "}
                      Products
                    </h2>
                    <h4>Accuracy and reliability with our instruments.</h4>
                  </div>
                </div>
              </div>
              {/* TITLE END */}
              {/* GALLERY CONTENT START */}
              <div className="portfolio-wrap mfp-gallery work-grid row clearfix">
                {projects.map((item, index) => (
                  <div
                    key={index}
                    className={`${item.filter} masonry-item col-md-4 col-sm-6 m-b30`}
                  >
                    <div className="image-effect-two hover-shadow">
                      <img src={item.thumb.default} alt="" />
                      <div className="figcaption">
                        <h4 className="mt-tilte">{item.title}</h4>
                        <p>{item.description}</p>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              {/* GALLERY CONTENT END */}
            </div>
          </div>
          {/* SECTION CONTENT END  */}
          <div className="m-t50 text-center products-button">
            <NavLink to={"/products"} className="site-button btn-effect">
              See all products
            </NavLink>
          </div>
        </div>
      </>
    );
  }
}

export default ProjectGrid;
