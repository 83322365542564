import React from "react";
import Navigation from "../Common/Navigation";
import { NavLink } from "react-router-dom";

class Header extends React.Component {
  state = { isQuoteActive: false };

  constructor(props) {
    super(props);
    this.state = {
      logo: require("./../../images/logo-dark.png"),
    };
  }

  handleQuoteToggle = () => {
    this.setState({ isQuoteActive: !this.state.isQuoteActive });
  };

  render() {
    const isQuoteActive = this.state.isQuoteActive;

    return (
      <>
        <header className="site-header header-style-1">
          <div className="top-bar bg-geotech">
            <div className="container">
              <div className="d-flex justify-content-center">
                <div className="logo-header">
                  <div className="logo-header-inner logo-header-one">
                    <NavLink to={"/"}>
                      <img
                        src={this.state.logo.default}
                        alt="Geotech"
                        style={{ paddingBottom: "4px" }}
                      />
                    </NavLink>
                  </div>
                </div>
              </div>

              <div className="mt-topbar-right clearfix move-right-gt">
                <div className="appint-btn">
                  <NavLink to={"/iconboxstyle"} className="site-button white">
                    <img
                      src={"/assets/media/images/lang-region-icon.svg"}
                      alt={""}
                      width={16}
                      style={{
                        marginRight: "8px",
                        marginBottom: "2px",
                        color: "#fff",
                      }}
                    />
                    Contact us now
                  </NavLink>
                </div>
              </div>
            </div>
          </div>
          <div
            className="sticky-header main-bar-wraper"
            style={{ maxHeight: "57px" }}
          >
            <div
              className="main-bar bg-white shadow-gt"
              style={{ height: "57px" }}
            >
              <div className="container" style={{ height: "100%" }}>
                {/* NAV Toggle Button */}
                <button
                  data-target=".header-nav"
                  data-toggle="collapse"
                  type="button"
                  className="navbar-toggle collapsed"
                  style={{ height: "57px", margin: 0, marginLeft: "5px" }}
                >
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar" />

                  <img
                    src={"/assets/media/images/services-menu-icon.svg"}
                    alt={"Location"}
                  />
                </button>
                {/* ETRA Nav */}
                <div className="extra-nav" style={{ height: "56px" }}>
                  <div className="extra-cell">
                    <NavLink
                      to={"#"}
                      className="contact-slide-show"
                      onClick={this.handleQuoteToggle}
                    >
                      <i className="fa fa-angle-left arrow-animation" />
                    </NavLink>
                  </div>
                </div>
                {/* ETRA Nav */}
                {/* Contact Nav */}
                <div
                  className="contact-slide-hide "
                  style={{
                    //backgroundImage: "url(" + bnr.default + ")",
                    right: isQuoteActive ? "0px" : "-500px",
                  }}
                >
                  <div className="contact-nav">
                    <NavLink
                      to={"#"}
                      className="contact_close"
                      onClick={this.handleQuoteToggle}
                    >
                      ×
                    </NavLink>
                    <div className="contact-nav-form p-a30">
                      <div className="contact-info   m-b30">
                        <div className="mt-icon-box-wraper center p-b30">
                          <div className="icon-xs m-b20 scale-in-center">
                            <i className="fa fa-phone" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Phone number
                            </h5>
                            <p>(+383)-49-74-74-79</p>
                            <p>(+355)-67-20-88-060</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper center p-b30">
                          <div className="icon-xs m-b20 scale-in-center">
                            <i className="fa fa-envelope" />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Email address
                            </h5>
                            <p>info@geotech.al</p>
                          </div>
                        </div>
                        <div className="mt-icon-box-wraper center p-b30">
                          <div className="icon-xs m-b20 scale-in-center">
                            <img
                              src={"/assets/media/images/find-dealer-icon.svg"}
                              alt={"Location"}
                            />
                          </div>
                          <div className="icon-content">
                            <h5 className="m-t0 font-weight-500">
                              Address Albania
                            </h5>
                            <p>
                              St. George W. Bush, Kulla 1, Kati 1, Nr. 20, 1000,
                              Tirana
                            </p>
                            <h5 className="m-t0 font-weight-500">
                              Address Kosovo
                            </h5>
                            <p>
                              St. B – Muharrem Fejza, Kompleksi KAWA, A, nr.3.
                              10000, Prishtina
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="full-social-bg">
                        <ul>
                          <li>
                            <a
                              href="https://www.facebook.com/geotech.al/"
                              className="facebook"
                            >
                              <i className="fa fa-facebook" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.linkedin.com/company/geotech"
                              className="-linkedin"
                            >
                              <i className="fa fa-linkedin" />
                            </a>
                          </li>
                          <li>
                            <a
                              href="https://www.instagram.com/geotech_al/?hl=en"
                              className="instagram"
                            >
                              <i className="fa fa-instagram" />
                            </a>
                          </li>
                        </ul>
                      </div>
                      <div className="text-center">
                        <h4 className="font-weight-600">© 2022 Geotech</h4>
                      </div>
                    </div>
                  </div>
                </div>

                {/* MAIN Vav */}
                <Navigation />
              </div>
            </div>
          </div>
        </header>
      </>
    );
  }
}

export default Header;
