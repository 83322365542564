import React, {useContext, useEffect, useState} from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import "../../../node_modules/react-modal-video/css/modal-video.min.css";
import {host} from "../../settings";
import OwlCarousel from "react-owl-carousel";
import {AppContext} from "../../contexts/AppContext";
import {useNavigate, useParams} from "react-router-dom";

var bnrimg = require("./../../images/banner/1.jpg");

const options = {
    loop: true,
    autoplay: true,
    center: true,
    items: 1,
    margin: 140,
    nav: true,
    dots: true,
    navText: [
        '<i class="fa fa-angle-left"></i>',
        '<i class="fa fa-angle-right"></i>',
    ],
    responsive: {
        0: {
            items: 1,
        },
        768: {
            items: 1,
        },
        991: {
            items: 1,
        },
        1200: {
            items: 1,
        },
    },
};

const ProjectDetail = () => {
    let {id} = useParams();
    const {productState} = useContext(AppContext);
    const [product, setProduct] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (!id || !productState?.products) {
            navigate("./");
            return;
        }

        const product = productState.products.find(
            (product) => product.id === parseInt(id)
        );

        if (!product) {
            navigate("./");
            return;
        }

        setProduct(product);
    }, []);

    const headerBgImage = product?.images?.find(
        (image) => image.type === "header"
    );
    const bannerImage = headerBgImage
        ? host + headerBgImage.url.replace("\\", "/")
        : product?.image
            ? product.image.replace("\\", "/")
            : bnrimg.default;

    return (
        <>
            <Header/>

            <div className="page-content">
                <Banner
                    title={
                        product?.title?.toUpperCase()
                            ? product?.title?.toUpperCase() +
                            ", Ready for outside, powered from within."
                            : "Ready for outside, powered from within."
                    }
                    pagename="Project Detail"
                    bgimage={bannerImage}
                    className={"product-header-gt"}
                />

                {/* SECTION CONTENT START */}
                <div className="section-full p-tb40 inner-page-padding">
                    <div className="container">
                        <div className="row">
                            <div className=" col-lg-5 col-md-4">
                                <div className="product-block">
                                    <div className="row">
                                        <div className="col-md-12 m-b10">
                                            <h4 className="text-uppercase font-weight-600 m-b10">
                                                {product?.title}
                                            </h4>
                                        </div>

                                        <div className="col-md-12 m-b10">
                                            <p>{product?.address}</p>
                                        </div>

                                        <div className="col-md-12 m-b10">
                                            <h4 className="text-uppercase font-weight-600 m-b10">
                                                Producer
                                            </h4>
                                            <p>{product?.make}</p>
                                        </div>
                                        <div className="col-md-12 m-b10">
                                            <h4 className="text-uppercase font-weight-600 m-b10">
                                                Type
                                            </h4>
                                            <p>{product?.filterName}</p>
                                        </div>
                                        <div className="col-md-12 m-b10 mb-5">
                                            <h4 className="text-uppercase font-weight-600 m-b10">
                                                Specifications
                                            </h4>
                                            <p>{product?.specs}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="project-detail-outer mb-4">
                                <div className="row">
                                    <div className=" col-lg-6 col-md-4 col-sm-4">
                                        <div className="project-detail-pic m-b30">
                                            <div className="mt-media">
                                                <img src={product?.image} alt=""/>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        {product?.images && (
                            <OwlCarousel
                                className="owl-carousel work-carousel owl-btn-vertical-center"
                                {...options}
                            >
                                {product?.images
                                    ?.filter((p) => p.type !== "header")
                                    .map((item, index) => (
                                        <div key={index} className="item mt-box">
                                            <div>
                                                <img src={host + item.url} alt=""/>
                                            </div>
                                        </div>
                                    ))}
                            </OwlCarousel>
                        )}
                    </div>
                </div>
            </div>

            <Footer/>
        </>
    );
}

export default ProjectDetail;
