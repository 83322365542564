import React from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import ShortcodesNav from "./../Elements/ShortcodesNav";

var bnrimg = require("./../../images/banner/1.jpg");

class IconBoxStyle extends React.Component {
  render() {
    return (
      <>
        <Header />
        <div className="page-content">
          <Banner
            title="In our work we have pride, quality is what we provide"
            pagename="Icon Box"
            bgimage={bnrimg.default}
          />

          {/* SECTION CONTENTG START */}
          <div className="section-full p-tb80 bg-white inner-page-padding">
            {/* LOCATION BLOCK*/}
            <div className="container">
              <div className="row">
                <ShortcodesNav />
                <div className="col-md-9 col-sm-8">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-black text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">
                            Contact
                          </span>{" "}
                          Us
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  {/* ICON BOX */}
                  <div className="section-content m-b0">
                    {/* ICON BOX */}
                    <div className="section-content m-b50">
                      <div className="row">
                        <div className="col-md-12 col-sm-12 m-b30">
                          <div className="mt-icon-box-wraper bx-style-1 p-a30 center bg-white hover-shadow">
                            <div className="icon-xl m-b20 scale-in-center">
                              <span className="icon-cell text-primary">
                                <i className="fa fa-envelope-square" />
                              </span>
                            </div>
                            <div className="icon-content">
                              <h4 className="mt-tilte">info@geotech.al</h4>
                              <p>www.geotech.al</p>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6 col-sm-6 m-b30">
                        <div className="mt-icon-box-wraper bx-style-1 p-a20 left bg-white block-shadow">
                          <div className="mt-icon-box-xs text-secondry bg-white radius bdr-1  bdr-solid scale-in-center">
                            <span className="icon-cell text-secondry">
                              <img
                                src={
                                  "/assets/media/images/find-dealer-icon.svg"
                                }
                                alt={"Location"}
                              />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="mt-tilte">Geotech Albania</h4>
                            <p>
                              St. “George W. Bush”, Kulla 1, Kati 1, Nr. 20 ,
                              1000, Tirana
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 m-b30">
                        <div className="mt-icon-box-wraper bx-style-1 p-a20 right bg-white hover-shadow">
                          <div className="mt-icon-box-xs text-secondry bg-white radius bdr-1  bdr-solid scale-in-center">
                            <span className="icon-cell text-secondry">
                              <img
                                src={
                                  "/assets/media/images/find-dealer-icon.svg"
                                }
                                alt={"Location"}
                              />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="mt-tilte">Geotech Kosovo</h4>
                            <p>
                              St. B – Muharrem Fejza, Kompleksi KAWA, Lamela A,
                              nr.3. 10000, Prishtina
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 m-b30">
                        <div className="mt-icon-box-wraper bx-style-1 p-a20 left bg-white hover-shadow">
                          <div className="icon-sm m-b20 scale-in-center">
                            <span className="icon-cell text-secondry">
                              <i className="fa fa-phone" />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="mt-tilte">Phone</h4>
                            <p>
                              +355 67 20 88 060 <br /> +355 69 20 88 060
                            </p>
                          </div>
                        </div>
                      </div>
                      <div className="col-md-6 col-sm-6 m-b30">
                        <div className="mt-icon-box-wraper bx-style-1 p-a20 right bg-white hover-shadow">
                          <div className="icon-sm m-b20 scale-in-center">
                            <span className="icon-cell text-secondry">
                              <i className="fa fa-phone" />
                            </span>
                          </div>
                          <div className="icon-content">
                            <h4 className="mt-tilte">Phone</h4>
                            <p>+383 49 74 74 79 </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          {/* SECTION CONTENT END */}
        </div>

        <Footer />
      </>
    );
  }
}

export default IconBoxStyle;
