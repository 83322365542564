import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Slider from './../Elements/Slider';
import Specialization from './../Elements/Specialization';
import About from './../Elements/About';
import OurValue from './../Elements/OurValue';
import OurMission from './../Elements/OurMission';
import OurServices from './../Elements/OurServices';
import Callus from './../Elements/Callus';
import ProjectGrid from "./ProjectGrid";
import {Toaster} from "react-hot-toast";

class Home extends React.Component {
    render() {
        return (
            <>
                <Header/>
                <Toaster/>
                <div className="page-content">
                    <div className="main-slider">
                        <Slider/>
                    </div>
                    <Specialization/>
                    <About/>
                    <OurValue/>
                    <OurMission/>
                    <ProjectGrid/>
                    <OurServices/>
                    <Callus/>
                </div>
                <Footer/>
            </>
        );
    };
}

export default Home;