import axios from 'axios';
import {apiHost} from "./settings";

const fetchClient = () => {

    let instance = axios.create({
        timeout: 10 * 60 * 1000,
        baseURL: apiHost
    });

    function extracted(config) {
        const token = localStorage.getItem('token');
        if (token) {
            config.headers['authorization'] = 'Bearer ' + token;
        }
    }

    instance.interceptors.request.use(config => {
        extracted(config);
        return config;
    });

    return instance;
};

const httpClient = fetchClient();

export default httpClient;