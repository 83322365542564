import React from 'react';
import Header from './../Common/Header';
import Footer from './../Common/Footer';
import Banner from './../Elements/Banner';
import AboutSummary from './../Elements/AboutSummary';

var bnrimg = require('./../../images/banner/1.jpg');

class About extends React.Component {
    render() {
        return (
            <>

                <Header />
                <div className="page-content">
                    <Banner title="You can't manage what you can't measure." pagename="About Us" bgimage={bnrimg.default}/>
                    <AboutSummary />
                </div>

                <Footer />

            </>
        );
    };
};

export default About;