import React from 'react';
import {NavLink} from 'react-router-dom';
import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';

class ShortcodesNav extends React.Component {
    render() {

        const options = {
            loop: true,
            autoplay: true,
            margin: 30,
            nav: false,
            dots: true,
            navText: ['<i class="fa fa-angle-left"></i>', '<i class="fa fa-angle-right"></i>'],
            responsive: {
                0: {
                    items: 1
                },
                991: {
                    items: 1
                }
            }
        };

        return (
            <>
                <div className="col-md-3 col-sm-4">
                    <div className="hover-navigation bg-white m-b30">
                        <ul>
                            <li><NavLink to={"/iconboxstyle"} data-hover="Contact">Contact</NavLink></li>
                            <li><NavLink to={"/accordian"} data-hover="Online Form">Online Form</NavLink></li>

                        </ul>
                    </div>
                </div>
            </>
        );
    }
}

export default ShortcodesNav;