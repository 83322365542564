import React, { useState } from "react";
import Header from "./../Common/Header";
import Footer from "./../Common/Footer";
import Banner from "./../Elements/Banner";
import ShortcodesNav from "./../Elements/ShortcodesNav";
import httpClient from "../../AxiosConfig";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

var bnrimg = require("./../../images/banner/1.jpg");

const Accordian = () => {
  const [applyDetails, setApplyDetails] = useState({
    clientName: "",
    email: "",
    companyName: "",
    phoneNumber: "",
    productType: "",
    productModel: "",
    productProducer: "",
    productSerialNo: "",
    message: "",
    type: 2,
    date: new Date().toISOString(),
  });

  const handleChangeValue = (e) => {
    setApplyDetails({
      ...applyDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();

    httpClient
      .post("Geotech", applyDetails)
      .then((res) => {
        if (res.status === 200) {
          setApplyDetails({
            clientName: "",
            email: "",
            companyName: "",
            phoneNumber: "",
            productType: "",
            productModel: "",
            productProducer: "",
            productSerialNo: "",
            message: "",
            type: 2,
          });
          toast.success(
            "Your data has been submitted. Our team will soon contact you."
          );
        }
      })
      .catch((err) => {
        toast.error(
          "There was a problem with the data submission. Please try again later!"
        );
      });
  };

  return (
    <>
      <Header />
      <div>
        <Toaster />
      </div>
      <div className="page-content">
        <Banner
          title="Don’t get framed by the competition, trust our solid reputation."
          pagename="Accordian"
          bgimage={bnrimg.default}
        />

        {/* SECTION CONTENTG START */}
        <div className="section-full p-tb80 inner-page-padding">
          <div className="container">
            <div className="row">
              <ShortcodesNav />
              <div className="col-md-9 col-sm-8">
                <div className="m-b50">
                  {/* TITLE START */}
                  <div className="section-head">
                    <div className="mt-separator-outer separator-left">
                      <div className="mt-separator">
                        <h2 className="text-black text-uppercase sep-line-one ">
                          <span className="font-weight-300 text-primary">
                            Online
                          </span>{" "}
                          Application
                        </h2>
                      </div>
                    </div>
                  </div>
                  {/* TITLE END */}
                  {/* ACCORDIAN  BACKGROUND GRAY */}
                  <form onSubmit={handleOnSubmit}>
                    <div className="section-content ">
                      <div className="mt-accordion acc-bg-gray" id="accordion5">
                        <div className="panel mt-panel">
                          <div className="acod-head acc-actives">
                            <h6 className="acod-title m-b30">
                              <a
                                data-toggle="collapse"
                                href="#collapseOne5"
                                data-parent="#accordion5"
                              >
                                Your data
                                <span className="indicator">
                                  <i className="fa" />
                                </span>
                              </a>
                            </h6>
                          </div>
                          <div
                            id="collapseOne5"
                            className="acod-body collapse in"
                          >
                            <div className="col-md-12 col-sm-12 p-tb15">
                              <div className="contact-home1-left bg-gray p-a30 m-b10">
                                <h3 className="text-black m-t0">
                                  <span className="font-weight-100">Your</span>{" "}
                                  Details
                                </h3>
                                <form
                                  className="cons-contact-form2 form-transparent"
                                  method="post"
                                  action=""
                                >
                                  <div className="input input-animate">
                                    <label htmlFor="name">Full Name</label>
                                    <input
                                      type="text"
                                      name={"clientName"}
                                      id="name"
                                      required
                                      value={applyDetails.clientName}
                                      onChange={handleChangeValue}
                                    />
                                    <span className="spin" />
                                  </div>
                                  <div className="input input-animate">
                                    <label htmlFor="email">Email</label>
                                    <input
                                      type="email"
                                      name={"email"}
                                      id="email"
                                      required
                                      value={applyDetails.email}
                                      onChange={handleChangeValue}
                                    />
                                    <span className="spin" />
                                  </div>
                                  <div className="input input-animate">
                                    <label htmlFor="companyName">
                                      Company Name
                                    </label>
                                    <input
                                      type="text"
                                      name={"companyName"}
                                      id="companyName"
                                      required
                                      value={applyDetails.companyName}
                                      onChange={handleChangeValue}
                                    />
                                    <span className="spin" />
                                  </div>
                                  <div className="input input-animate">
                                    <label htmlFor="Phone">Phone</label>
                                    <input
                                      type="number"
                                      name={"phoneNumber"}
                                      id="Phone"
                                      required
                                      value={applyDetails.phoneNumber}
                                      onChange={handleChangeValue}
                                    />
                                    <span className="spin" />
                                  </div>
                                  <div className="input input-animate">
                                    <label htmlFor="message">
                                      Describe your case
                                    </label>
                                    <textarea
                                      name={"message"}
                                      id="message"
                                      required
                                      defaultValue={""}
                                      name={"message"}
                                    />
                                    <span className="spin" />
                                  </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="panel mt-panel">
                          <div className="acod-head">
                            <h6 className="acod-title">
                              <a
                                data-toggle="collapse"
                                href="#collapseTwo5"
                                className="collapsed"
                                data-parent="#accordion5"
                              >
                                Device data
                                <span className="indicator">
                                  <i className="fa" />
                                </span>
                              </a>
                            </h6>
                          </div>
                          <div id="collapseTwo5" className="acod-body collapse">
                            <div className="acod-content p-tb15">
                              <div className="col-md-12 col-sm-12 m-b20">
                                <div className="contact-home1-left bg-gray p-a30 m-b0">
                                  <h3 className="text-black m-t0">
                                    <span className="font-weight-100">
                                      Device
                                    </span>{" "}
                                    Details
                                  </h3>
                                  <form
                                    className="cons-contact-form2 form-transparent"
                                    method="post"
                                    action=""
                                  >
                                    <div className="input input-animate">
                                      <label htmlFor="deviceName">Type</label>
                                      <input
                                        type="text"
                                        name={"productType"}
                                        id="deviceName"
                                        required
                                        value={applyDetails.productType}
                                        onChange={handleChangeValue}
                                      />
                                      <span className="spin" />
                                    </div>
                                    <div className="input input-animate">
                                      <label htmlFor="model">Model</label>
                                      <input
                                        type="text"
                                        name={"productModel"}
                                        value={applyDetails.productModel}
                                        onChange={handleChangeValue}
                                        id="model"
                                        required
                                      />
                                      <span className="spin" />
                                    </div>
                                    <div className="input input-animate">
                                      <label htmlFor="serialNumber">
                                        Serial Number
                                      </label>
                                      <input
                                        type="number"
                                        name={"productSerialNo"}
                                        value={applyDetails.productSerialNo}
                                        onChange={handleChangeValue}
                                        id="serialNumber"
                                        required
                                      />
                                      <span className="spin" />
                                    </div>
                                    <div className="input input-animate">
                                      <label htmlFor="producer">Producer</label>
                                      <input
                                        type="text"
                                        name={"productProducer"}
                                        value={applyDetails.productProducer}
                                        onChange={handleChangeValue}
                                        id="producer"
                                        required
                                      />
                                      <span className="spin" />
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="text-center p-t10 m-t30 buttons-form ">
                      <button
                        type="submit"
                        className="site-button btn-effect m-r30"
                      >
                        Submit Now
                      </button>
                      <Link to={"/"}>
                        <button
                          className="site-button btn-effect"
                          type="submit"
                        >
                          Cancel
                        </button>
                      </Link>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* SECTION CONTENT END */}
      </div>

      <Footer />
    </>
  );
};

export default Accordian;
