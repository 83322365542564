import React from 'react';

var img1 = require('./../../images/background/bg-6.png');
var img2 = require('./../../images/background/line.png');

class AboutSummary extends React.Component {
    componentDidMount(){
        function loadScript(src) {
           
          return new Promise(function(resolve, reject){
            var script = document.createElement('script');
            script.src = src;
            script.addEventListener('load', function () {
              resolve();
            });
            script.addEventListener('error', function (e) {
              reject(e);
            });
            document.body.appendChild(script);
            document.body.removeChild(script);
          })
        };
 
      loadScript('./assets/js/masonary.js');
      
    };
    render() {
        return (
            <>
                <div className="section-full p-t80 p-b80 bg-dark-blue bg-repeat square_shape2 inner-page-padding" style={{ backgroundImage: 'url(' + img1.default + ')' }}>
                    <div className="container">
                        {/* TITLE START */}
                        <div className="section-head">
                            <div className="mt-separator-outer separator-left">
                                <div className="mt-separator text-white">
                                    <h2 className="text-uppercase sep-line-one "><span className="font-weight-300 text-primary">About</span> Us</h2>
                                </div>
                            </div>
                        </div>
                        {/* TITLE END */}
                        <div className="section-content our-story">
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic4.jpg').default} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover" style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                        <h4 className="text-uppercase m-b20">Explore the best solutions</h4>
                                        <h4 className="m-b20">Accuracy and Reliability.</h4>
                                        <p>GeoTech Albania sh.p.k. is the exclusive representative of TOPCON & SOKKIA L.t.d for Albania and Kosovo.
                                            For the needs of construction companies, surveillance units, industrial centers, professionals and interns.
                                           Committed to provide products and solutions that are the most accurate, reliable and designed to meet the needs of our customers in our areas of activity: surveillance and measurement in construction and industry.
                                            With the support and expertise, the information fund, instruments and software available from Topcon & Sokkia L.T.D, Geotech brings to Albania and Kosovo focus on creating accurate, trusted and qualitative instruments for industrial and construction companies.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic7.jpg').default} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                        <h4 className="text-uppercase m-b20">Topcon & Sokkia</h4>
                                        <h4 className="m-b20">Instruments and methodologies</h4>
                                        <p>
                                            Topcon & Sokkia is a world leader in researching, designing, developing and providing sustainable and functional solutions of businesses and individuals for geodetic methodologies, equipment, equipment and software.
                                            For the first time in Albania and Kosovo, Geotech brings Topcon & Sokkia to perform the same task: leading the market for geodetic instruments and methodologies, emancipating the offer.
                                            Topcon Positioning Systems Inc., provides positioning technology for surveyors, civil engineers, construction contractors, equipment owners and operators.
                                            Topcon Corporation acquired Advanced Grade Technology in 1995 and became known as Topcon Laser Systems and in August 2000, Topcon acquired JPS Inc., of San Jose, California, a provider of precision GPS and GPS/GLONASS products.
                                            Sokkia Co., Ltd. was founded in 1920 as Sokkisha in Japan. It makes measurement instruments for the surveying, construction and industrial measurement industries. In 2008 Sokkia was acquired by Topcon, but still
                                            maintains a separate brand.
                                        </p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic6.jpg').default} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                        <h4 className="text-uppercase m-b20">Wingtra</h4>
                                        <h4 className="m-b20">Fast and accurate photogrammetry</h4>
                                        <p> Wingtra is the world's leading VTOL drone producer, headquartered in
                                            Switzerland. Since its market entry in early 2017, Wingtra has partnered
                                            with more than 80 of the biggest surveying equipment dealers across 50+
                                            countries worldwide.
                                            Based in the heart of Zurich, Switzerland, Wingtra houses 100+
                                            employees, out of which more than 40 focus on R&D. The majority of
                                            Wingtra's engineers are graduates of one of the best science
                                            universities in the world, ETH Zurich. The same engineers design and
                                            assemble drones in-house and form Wingtra's support team, which is based
                                            in Europe and the Americas.</p>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-md-4 col-sm-6">
                                    <div className="our-story-pic-block">
                                        <div className="mt-media our-story-pic">
                                            <img src={require('./../../images/gallery/portrait/pic5.jpg').default} alt=""/>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-8 col-sm-6">
                                    <div className="mt-box our-story-detail bg-moving bg-cover"  style={{ backgroundImage: 'url(' + img2.default + ')' }}>
                                        <h4 className="text-uppercase m-b20">Geonet</h4>
                                        <h4 className="m-b20">Based on collaboration with Topcon</h4>
                                        <p>Geonet network consists of 7 permanent stations scattered ready in
                                            uniformly throughout the territory of Kosovo and in the border areas.
                                            Is the first nationwide system in Kosovo that offers corrections for
                                            GNSS measurement in four GPS, Glonass, Galileo and Beidou systems.
                                            Data obtained from permanent stations are processed by software
                                            Topnet+, and then based on them, generated corrections for GNSS
                                            measurements
                                            in real time.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </>
        );
    }
};

export default AboutSummary;