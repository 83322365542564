import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./Pages/Home";
import About from "./Pages/About";
import Services from "./Pages/Services";
import Faq from "./Pages/Faq";
import Error from "./Pages/Error";
import ContactUs from "./Pages/ContactUs";
import ProjectCarousel from "./Pages/ProjectCarousel";
import ProjectDetail from "./Pages/ProjectDetail";
import ProjectGrid from "./Pages/ProjectGrid";
import Accordian from "./Pages/Accordian";
import OurProject from "./Pages/OurProject";
import IconBoxStyle from "./Pages/IconBoxStyle";

const Components = () => {
    return (
        <div className="page-wraper">
            <BrowserRouter>
                <Routes>
                    <Route path="/" element={<Home/>}/>

                    <Route path="/about" element={<About/>}/>
                    <Route path="/services" element={<Services/>}/>
                    <Route path="/faq" element={<Faq/>}/>
                    <Route path="/error" element={<Error/>}/>
                    <Route path="/contactus" element={<ContactUs/>}/>

                    <Route path="/project-carousel" element={<ProjectCarousel/>}/>
                    <Route path="/products" element={<OurProject/>}/>
                    <Route path="/products/:id" element={<ProjectDetail/>}/>
                    <Route path="/project-grid" element={<ProjectGrid/>}/>
                    <Route path="/iconboxstyle" element={<IconBoxStyle/>}/>
                    <Route path="/accordian" element={<Accordian/>}/>

                    <Route element={<Error/>}/>
                </Routes>
            </BrowserRouter>
        </div>
    );
};

export default Components;
