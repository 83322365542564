import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import httpClient from "../../AxiosConfig";
import { toast } from "react-hot-toast";

var img1 = require("./../../images/left-men.png");
var img2 = require("./../../images/background/bg-4.png");
var img3 = require("./../../images/background/bg-site.png");
var img4 = require("./../../images/mission.jpg");

const OurMission = () => {
  const [contactDetails, setContactDetails] = useState({
    fullName: "",
    email: "",
    subject: "",
    message: "",
    type: 1,
  });

  const handleChangeValue = (e) => {
    setContactDetails({
      ...contactDetails,
      [e.target.name]: e.target.value,
    });
  };

  const handleOnSubmit = (e) => {
    e.preventDefault();
    httpClient
      .post("Geotech", contactDetails)
      .then((res) => {
        if (res.status === 200) {
          setContactDetails({
            fullName: "",
            email: "",
            subject: "",
            message: "",
            type: 1,
          });
          toast.success(
            "Your data has been submitted. Our team will soon contact you."
          );
        }
      })
      .catch((err) => {
        toast.error(
          "There was a problem with the data submission. Please try again later!"
        );
      });
  };
  return (
    <>
      <div
        className="section-full mobile-page-padding mission-outer-section  p-t80 p-b30 bg-gray bg-no-repeat bg-right-center"
        style={{
          backgroundImage:
            "url(" + img1.default + "), url(" + img2.default + ")",
        }}
      >
        <div className="section-content">
          <div className="container">
            {/* TITLE START */}
            <div className="section-head">
              <div className="mt-separator-outer separator-center">
                <div className="mt-separator">
                  <h2 className="text-uppercase sep-line-one ">
                    <span className="font-weight-300 text-primary">Our</span>{" "}
                    Mission
                  </h2>
                </div>
              </div>
            </div>
            {/* TITLE END */}
            <div className="row">
              <div className="col-md-4 col-sm-6">
                <div
                  className="mission-left bg-white m-b30 p-a30 bg-no-repeat bg-bottom-left"
                  style={{ backgroundImage: "url(" + img3.default + ")" }}
                >
                  <h3 className="m-t0">
                    <span className="font-weight-100"> What We </span>Serve
                  </h3>
                  <p>
                    Accurate, trusted and qualitative instruments from world
                    leaders in research, design and development for geodetic
                    methodologies, equipment and software.
                  </p>
                  <ul className="list-angle-right anchor-line">
                    <li>
                      <NavLink to="/about">Knowledge and information</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about">
                        Accurate and reliable geodetic instruments
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/about">Servicing and calibration</NavLink>
                    </li>
                    <li>
                      <NavLink to="/about">
                        Successful internships and effective training
                      </NavLink>
                    </li>
                    <li>
                      <NavLink to="/about">Leed consultation</NavLink>
                    </li>
                  </ul>
                  <div className="text-right">
                    <NavLink
                      to="/about"
                      className="site-button-link"
                      data-hover="Read More"
                    >
                      Read More{" "}
                      <i className="fa fa-angle-right arrow-animation" />
                    </NavLink>
                  </div>
                </div>
              </div>
              <div className="col-md-4 col-sm-6">
                <div
                  className="mission-mid bg-no-repeat bg-cover m-b30"
                  style={{ backgroundImage: "url(" + img4.default + ")" }}
                />
              </div>
              <div className="col-md-4 col-sm-12">
                <div className="contact-home1-left bg-dark p-a30 m-b0">
                  <h3 className="text-white m-t0">
                    <span className="font-weight-100">Get In</span> Touch
                  </h3>
                  <form
                    className="cons-contact-form2 form-transparent"
                    method={"POST"}
                    onSubmit={handleOnSubmit}
                    action=""
                  >
                    <div className="input input-animate">
                      <label htmlFor="name">Name</label>
                      <input
                        type="text"
                        id="name"
                        required
                        // className="spin"
                        name={"fullName"}
                        value={contactDetails.firstName}
                        onChange={handleChangeValue}
                      />
                      <span />
                    </div>
                    <div className="input input-animate">
                      <label htmlFor="email">Email</label>
                      <input
                        type="email"
                        id="email"
                        required
                        name={"email"}
                        value={contactDetails.email}
                        onChange={handleChangeValue}
                      />
                      <span className="spin" />
                    </div>
                    <div className="input input-animate">
                      <label htmlFor="Phone">Phone</label>
                      <input
                        type="text"
                        name="phone"
                        id="Phone"
                        required
                        name={"subject"}
                        value={contactDetails.subject}
                        onChange={handleChangeValue}
                      />
                      <span className="spin" />
                    </div>
                    <div className="input input-animate">
                      <label htmlFor="message">Textarea</label>
                      <textarea
                        id="message"
                        required
                        name={"message"}
                        rows="3"
                        value={contactDetails.message}
                        onChange={handleChangeValue}
                      />
                      <span className="spin" />
                    </div>
                    <div className="text-center p-t10">
                      <button type="submit" className="site-button btn-effect ">
                        Submit Now
                      </button>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OurMission;
